<template>
    <Dropdown
        ref="dropdown"
        type="select"
        role="combobox"
        size="xxxs" heading
        v-bind="$attrs"
        :disabled="disabled"
        :readonly="readonly"
    >
        <template #default>
            <slot name="default" />
        </template>
        <template #element="{ toggle, open, close, onKeydown }">
            <slot name="element" v-bind="{ toggle, open, close, onKeydown }" />
        </template>
        <template #menu="menuBind">
            <slot name="menu" v-bind="menuBind" />
            <template
                v-for="(option, index) in options"
                :key="index"
            >
                <hr
                    v-if="option.divider"
                    v-bind="menuBind.divider"
                />
                <Txt v-else-if="option.marker" size="marker">
                    {{ option.marker }}
                </Txt>
                <span v-else-if="option.slot" v-bind="menuBind">
                    <slot
                        :name="option.slot"
                        :option="option"
                    />
                </span>
                <Clickable
                    v-else-if="!option.hidden"
                    :class="{ selected: selected === index, 'visually-enabled': option.visuallyEnabled }"
                    class="option"
                    role="option"
                    tabindex="-1"
                    :aria-label="option.label || option.name || option"
                    :disabled="option.disabled"
                    type="button"
                    @click="select(option, index)"
                >
                    <slot name="option" :option="option">
                        {{ option }}
                    </slot>
                </Clickable>
            </template>
            <slot name="menu:after" v-bind="menuBind" />
        </template>
    </Dropdown>
</template>

<script>
import Txt from '@/components/form/txt';
import Dropdown from '@/components/utility/dropdown';
import Clickable from '@/components/utility/clickable';

export default {
    name: 'SelectDropdown',
    components: { Txt, Dropdown, Clickable },
    props: {
        value: { type: [String, Object, Boolean], default: null },
        options: { type: Array, default: null },

        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false }
    },
    emits: ['update:value'],
    slots: ['default', 'element', 'menu'],
    data() {
        return {
            selected: 0
        };
    },
    watch: {
        value: {
            handler(to) {
                if (!to) return;
                if (!(this.options && this.options.length)) return;
                this.selected = this.options.indexOf(to);
            },
            immediate: true
        }
    },
    methods: {
        select(option, index) {
            if (this.disabled) return;
            this.selected = index;
            this.$emit('update:value', option);
        }
    }
};
</script>

<style lang="less">
.dropdown.select {
    .popup .popup-inner {
        > .txt-component.marker {
            margin: 8px;
            color: var(--anchor);
        }
    }
}
</style>
