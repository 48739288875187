<template>
    <div
        class="field"
        :class="[type, {
            disabled,
            dark,
            readonly: isReadonly,
            small,
            toolbar,
            inline,
            autocomplete,
            autofilled: isAutofilled,
            value: hasValue,
            focus: !isReadonly && isFocusing,
            'inline-label': inlineLabel
        }]"
    >
        <label v-if="showLabel" :for="id || uuid">
            <slot />
            <Txt v-if="required && showRequired && !isReadonly" size="xxs" heading class="anchor">({{ $trans('required') }})</Txt>
            <Txt v-if="!required && showOptional && !isReadonly" size="xxs" heading class="anchor">({{ $trans('optional') }})</Txt>
        </label>
        <div
            class="field-container"
            :class="{
                warning,
                rounded,
                stepper: type === 'number' && showControls,
                focus: !isReadonly && isFocusing,
                error: isInvalid && showErrorState,
            }"
        >
            <div class="input-wrapper" :class="{ relative: showInlineLength }">
                <slot name="before" />
                <template v-if="Array.isArray(value) && type === 'tags'">
                    <span
                        v-for="tag in value"
                        :key="tag"
                        class="tag"
                    >
                        <Txt size="s">
                            {{ tag }}
                        </Txt>
                        <Btn
                            v-if="isFocusing"
                            icon="cross"
                            icon-size="16"
                            variant="basic"
                            colour="anchor"
                            :icon-label="$trans('delete')"
                            @click.stop="removeTag(tag)"
                        />
                    </span>
                </template>
                <template v-if="type === 'tel'">
                    <SelectDropdown
                        v-model:value="selectedCountry"
                        variant="default"
                        colour="smoke"
                        class="locale"
                        :options="[
                            ...commonCountries,
                            { divider: true },
                            ...countries
                        ]"
                    >
                        {{ selectedCountry && selectedCountry.emoji }}
                        <template #option="{ option }">
                            {{ option.emoji }}
                            <Txt size="xxxs" heading>
                                {{ option.name }} ({{ option.code }})
                            </Txt>
                            <Icon
                                v-if="option === selectedCountry"
                                name="correct-circle"
                            />
                        </template>
                    </SelectDropdown>
                    <Txt
                        v-if="selectedCountry"
                        size="s"
                        class="area-code"
                    >
                        {{ selectedCountry.code }}
                    </Txt>
                </template>
                <textarea
                    v-if="type === 'textarea'"
                    :id="id || uuid"
                    ref="input"
                    :value="value"
                    :name="name"
                    :autocomplete="autocomplete"
                    :autocorrect="autocorrect"
                    :autocapitalize="autocapitalize"
                    :readonly="isReadonly"
                    :placeholder="placeholder"
                    :required="strict ? required : null"
                    :maxlength="strict ? maxlength : null"
                    :minlength="strict ? minlength : null"
                    :disabled="disabled"
                    :rows="_rows"
                    :wrap="wrap"
                    @input="update"
                    @keypress.enter="$emit('enter')"
                    @blur="isFocusing = false"
                    @focus="isFocusing = true"
                />
                <SelectDropdown
                    v-else-if="type === 'select'"
                    :options="options"
                    :value="value"
                    :position="position"
                    :colour="dark ? 'translucent-dark' : 'steel'"
                    :typeahead="typeahead"
                    :disabled="disabled"
                    :readonly="readonly"
                    :slim="small"
                    :max-height="maxHeight"
                    :chevron="!readonly"
                    full
                    @open="$emit('select:open')"
                    @update:value="update"
                    @focus="onFocus"
                >
                    <template #menu="menuBind">
                        <slot name="menu" v-bind="menuBind" />
                    </template>
                    <slot name="select:value" :value="value">
                        {{ value || 'Choose an option' }}
                    </slot>
                    <template #option="{ option }">
                        <slot name="select:option" :option="option">
                            {{ option }}
                        </slot>
                    </template>
                    <template #menu:after>
                        <slot name="menu:after" />
                    </template>
                </SelectDropdown>
                <input
                    v-else
                    :id="id || uuid"
                    ref="input"
                    :value="type === 'tags' ? textValue : value"
                    :name="name"
                    :type="_type"
                    :autocomplete="autocomplete"
                    :autocorrect="autocorrect"
                    :autocapitalize="autocapitalize"
                    :placeholder="type === 'tags' && Array.isArray(value) && value.length ? null : placeholder"
                    :required="strict ? required : null"
                    :maxlength="strict ? maxlength : null"
                    :minlength="strict ? minlength : null"
                    :max="strict ? max : null"
                    :min="strict ? min : null"
                    :readonly="isReadonly"
                    :disabled="disabled"
                    :size="size || 20"
                    :pattern="pattern"
                    :style="{ width: `${textWidth}px` }"
                    @keydown="onBackspace"
                    @keypress.enter="onKeydown($event); $emit('enter');"
                    @keypress.space="onKeydown"
                    @input="update"
                    @blur="onBlur"
                    @focus="onFocus"
                />
                <div v-if="clickable && disabled" class="click-handler" />
                <Txt
                    v-if="showInlineLength && !isReadonly && !disabled && (((!editable || isEditing) && isFocusing) || showLengthAlways)"
                    class="inline-length anchor"
                    size="marker"
                >
                    {{ maxlength - value.length }}
                </Txt>
                <Btn
                    v-if="type === 'password'"
                    variant="basic"
                    :colour="dark ? 'translucent' : 'anchor'"
                    :icon-size="iconSize"
                    :icon-label="$trans('showhide')"
                    :icon="isShowingPassword ? 'preview-slash' : 'preview'"
                    @click="isShowingPassword = !isShowingPassword"
                />
                <slot name="after" :is-editing="isEditing" />
                <template v-if="type === 'number' && showControls">
                    <Btn
                        variant="basic"
                        icon="minus"
                        icon-size="16"
                        :colour="dark ? 'translucent' : 'anchor'"
                        class="number-control minus"
                        :icon-label="$trans('subtract')"
                        :disabled="disabled || !canDecrease"
                        @mousedown="incrementNumber(-1)"
                        @mouseup="stopIncrementing"
                    />
                    <Btn
                        variant="basic"
                        icon="plus"
                        icon-size="16"
                        :colour="dark ? 'translucent' : 'anchor'"
                        class="number-control plus"
                        :icon-label="$trans('add')"
                        :disabled="disabled || !canIncrease"
                        @mousedown="incrementNumber(1)"
                        @mouseup="stopIncrementing"
                    />
                </template>
                <div v-if="editable" class="actions">
                    <template v-if="isEditing || status">
                        <Btn
                            key="cancel"
                            icon="cross"
                            icon-size="20"
                            colour="smoke"
                            :disabled="!!status"
                            :slim="small"
                            :icon-label="$trans('cancel')"
                            @click="cancel"
                        />
                        <Btn
                            key="save"
                            icon-size="20"
                            icon="correct"
                            colour="success"
                            :status="status"
                            :disabled="!!status || isInvalid"
                            :slim="small"
                            :icon-label="$trans('save')"
                            @click="save"
                        />
                    </template>
                    <Btn
                        v-else
                        key="edit"
                        icon="edit"
                        icon-size="20"
                        :disabled="disabled"
                        colour="smoke"
                        class="edit"
                        :slim="small"
                        :icon-label="$trans('edit')"
                        @click="edit"
                    />
                </div>
            </div>
            <div
                v-if="toolbar"
                class="toolbar"
            >
                <slot
                    name="toolbar"
                    :input="$refs.input"
                    :readonly="isReadonly"
                />
            </div>
        </div>
        <slot name="below" />
        <div v-if="showInfoAlways || (showInfo && (showErrorState || $slots.error || $slots.info || (!isReadonly && showLength)))" class="info-wrapper">
            <template v-if="$slots.error || showErrorState">
                <slot v-if="$slots.error" name="error" />
                <span v-else class="message">
                    <TransitionGroup name="fadedown">
                        <slot v-for="(validation, key) in invalid" :name="key" :message="validation.message">
                            <Txt
                                v-if="key !== 'required' || !isFocusing"
                                :key="key"
                                size="xxs"
                            >
                                <Icon name="danger" size="16" class="text-danger" />
                                {{ validation.message }}
                            </Txt>
                        </slot>
                    </TransitionGroup>
                </span>
            </template>
            <slot v-else-if="$slots.info" name="info" />
            <Transition name="fadedown">
                <span
                    v-if="!isReadonly && showLength"
                    class="length"
                    :class="{
                        danger: !isFocusing && invalid.required,
                        warning: invalid.maxlength || invalid.minlength
                    }"
                >
                    <strong>{{ value.length }}</strong>/{{ maxlength }}
                </span>
                <span v-else />
            </Transition>
        </div>
    </div>
</template>

<script>
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i;
const mailtoRegex = /mailto:[-a-zA-Z0-9@:%._+~#=]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/i;
const isHTML =/<\/?[a-z][\s\S]*>/i;

import Txt from '@/components/form/txt';
import Icon from '@/components/utility/icon';
import Btn from '@/components/utility/button';
import SelectDropdown from '@/components/form/select';

let uid = 0;

export default {
    name: 'Field',
    components: { Txt, Icon, Btn, SelectDropdown },
    props: {
        type: { type: String, default: 'text' },
        id: { type: String, default: undefined },
        name: { type: String, default: undefined },
        value: { type: [String, Number, Boolean, Object, Array], default: '' },
        options: { type: Array, default: () => [] },
        areaCode: { type: String, default: '' },
        size: { type: String, default: undefined },
        pattern: { type: RegExp, default: undefined },
        placeholder: { type: String, default: undefined },
        catchEdit: { type: Function, default: null },
        async: { type: Boolean, default: false },
        rows: { type: Number, default: 2 },
        wrap: { type: String, default: 'soft' },
        position: { type: String, default: null },

        warning: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: false },
        autocomplete: { type: String, default: 'off' },
        autocorrect: { type: String, default: 'off' },
        autocapitalize: { type: String, default: 'none' },
        disabled: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        typeahead: { type: Boolean, default: true },
        maxlength: { type: Number, default: undefined },
        minlength: { type: Number, default: undefined },
        max: { type: Number, default: undefined },
        min: { type: Number, default: undefined },
        strict: { type: Boolean, default: true },
        trim: { type: Boolean, default: false },

        inlineLabel: { type: Boolean, default: false },
        showLabel: { type: Boolean, default: true },
        slimLabel: { type: Boolean, default: false },
        iconSize: { type: String, default: '24' },
        showRequired: { type: Boolean, default: false },
        showOptional: { type: Boolean, default: false },
        showLength: { type: Boolean, default: false },
        showLengthAlways: { type: Boolean, default: false },
        showInlineLength: { type: Boolean, default: false },
        showControls: { type: Boolean, default: true },
        showInfo: { type: Boolean, default: true },
        showInfoAlways: { type: Boolean, default: false },
        editOnMount: { type: Boolean, default: false },
        urlError: { type: Boolean, default: false },
        maxHeight: { type: [Number, Boolean], default: true },

        autosize: { type: Boolean, default: false },
        inline: { type: Boolean, default: false },
        rounded: { type: Boolean, default: false },
        small: { type: Boolean, default: false },
        toolbar: { type: Boolean, default: false },
        editable: { type: Boolean, default: false },
        clickable: { type: Boolean, default: false },
        status: { type: String, default: undefined },
        skeleton: { type: Boolean, default: false },
        dark: { type: Boolean, default: false },
    },
    emits: ['error', 'enter', 'valid', 'focus', 'blur', 'update:value', 'update:area-code', 'step', 'edit', 'save', 'cancel', 'select:open'],
    slots: ['default', 'before', 'after', 'error', 'toolbar', 'info'],
    data() {
        return {
            uuid: undefined,
            textValue: null,
            cachedValue: null,

            selectedCountry: null,
            countries: [],

            context: null,
            textWidth: null,
            autorows: 1,
            autorowsTimer: null,
            incrementing: null,

            isFocusing: false,
            isEditing: false,
            isDirty: false,
            isAutofilled: false,
            isShowingPassword: false,
        };
    },
    computed: {
        _type() {
            if (this.type === 'password' && this.isShowingPassword) return 'text';
            return this.type;
        },
        _rows() {
            if (this.autosize) return this.autorows;
            return this.rows;
        },
        isReadonly() {
            return this.readonly || (this.editable && !this.isEditing);
        },
        validations() {
            const validations = {};

            if (this.required) validations.required = { invalid: !this.value, message: this.$trans('validation.required') };
            if (this.maxlength) validations.maxlength = { invalid: this.value && this.value.length > this.maxlength, message: this.$trans('validation.maxlength', { number: this.maxlength }) };
            if (this.minlength) validations.minlength = { invalid: this.value && this.value.length < this.minlength, message: this.$trans('validation.minlength', { number: this.minlength }) };
            if (this.max) validations.max = { invalid: this.value !== null && this.value > this.max };
            if (this.min) validations.min = { invalid: this.value !== null && this.value < this.min };
            if (this.type === 'email') validations.email = { invalid: this.value && !emailRegex.test(this.value), message: this.$trans('validation.email') };
            if (this.type === 'url') validations.url = { invalid: this.urlError || (this.value && !urlRegex.test(this.value)), message: this.$trans('validation.url') };
            if (this.type === 'hyperlink') validations.hyperlink = { invalid: this.value && !mailtoRegex.test(this.value) && !urlRegex.test(this.value), message: this.$trans('validation.url') };
            if (this.pattern) validations.pattern = { invalid: this.value && !this.pattern.test(this.value), message: this.$trans('validation.pattern') };

            return validations;
        },
        invalid() {
            const invalid = Object.keys(this.validations)
                .filter(key => this.validations[key].invalid)
                .map(key => ({ [key]: this.validations[key] }));

            return Object.assign({}, ...invalid);
        },
        hasValue() {
            return !!this.value;
        },
        isInvalid() {
            return !!(this.invalid && Object.keys(this.invalid).length);
        },
        showErrorState() {
            return !this.isReadonly && !this.isFocusing && this.isDirty && this.isInvalid;
        },
        canIncrease() {
            return this.max === undefined || Number(this.value) < this.max;
        },
        canDecrease() {
            return this.min === undefined || Number(this.value) > this.min;
        },
        selectedAreaCode() {
            if (!this.selectedCountry) return;
            return this.selectedCountry.code;
        },
        commonCountries() {
            if (!this.countries) return [];
            return ['United States', 'Canada', 'Mexico', 'Australia', 'New Zealand'].map(country => this.countries.find(c => country === c.name));
        }
    },
    watch: {
        type: {
            handler(to) {
                if (to === 'tel') {
                    import('@/helpers/i8n').then(({ countries }) => {
                        this.countries = countries;
                    });
                }
            },
            immediate: true
        },
        isEditing(to) {
            if (to) this.focus();
        },
        isInvalid: {
            handler(to) {
                this.$emit('valid', !to);
            },
            immediate: true
        },
        isFocusing(to) {
            this.$emit(to ? 'focus' : 'blur', this.value);
            if (to && this.value) this.isDirty = true;
        },
        value(to) {
            this.$emit('error', emailRegex.test(to));
        },
        countries: {
            handler(to) {
                if (to) this.selectedCountry = to.find(c => c.name === 'United States');
            },
            immediate: true
        },
        selectedAreaCode(to) {
            this.$emit('update:area-code', to);
        },
        canDecrease(to) {
            if (!to) this.stopIncrementing();
        },
        canIncrease(to) {
            if (!to) this.stopIncrementing();
        }
    },
    mounted() {
        if (this.editOnMount) this.isEditing = true;
        if (this.autofocus) this.focus();
        if (this.autocomplete !== 'off') this.autofill();
        if (this.autosize) {
            const canvas = document.createElement('canvas');
            this.context = canvas.getContext('2d');
            this.context.font = '14px Roboto, sans-serif';
            this.getTextWidth(this.value);
        }
    },
    created() {
        this.uuid = `vue-component-field-${uid}`;
        uid += 1;
    },
    methods: {
        blur() {
            this.$refs.input.blur();
        },
        focus() {
            this.$refs.input.focus();
        },
        autofill() {
            this.isDirty = false;
            this.isAutofilled = true;
        },
        getTextWidth(value) {
            if (!this.context) return;
            this.textWidth = this.context.measureText(value).width;
        },
        update(e) {
            this.isDirty = true;
            let value = e.target ? e.target.value : e;

            if (this.type === 'tags') return this.textValue = value;
            if (this.autosize) {
                if (this.type === 'textarea') {
                    this.autorowsTimer = this.$debounce(() => {
                        const input = this.$refs.input;
                        this.autorows = 1;
                        this.$nextTick(() => this.autorows = Math.floor(input.scrollHeight / parseInt(getComputedStyle(input).lineHeight, 10)));
                    }, 250, this.autorowsTimer);
                } else {
                    this.getTextWidth(value);
                }
            }

            if (this.type === 'number') {
                if (this.strict) {
                    value = Math.min(Math.max(Number(value), this.min || -Infinity), this.max || Infinity);
                }
                this.$emit('update:value', value);
            } else if (this.type === 'url') {
                const url = isHTML.test(value) ? this.getURLFromHTML(value) : value;
                this.$emit('update:value', url);
            } else {
                this.$emit('update:value', this.trim ? value.trim() : value);
            }
        },
        onFocus() {
            this.isFocusing = true;
            this.cachedValue = this.value;
        },
        onBlur() {
            this.isFocusing = false;
            this.isDirty = true;
            this.addTags();
            if (this.autocomplete !== 'off') return;
            if (this.required && !this.value && this.cachedValue) {
                this.$emit('update:value', this.cachedValue);
            }
        },
        onKeydown(event) {
            if (this.type !== 'tags') return;
            event.preventDefault();
            this.addTags();
        },
        addTags() {
            if (this.type !== 'tags') return;
            const value = this.$refs.input.value;
            const tags = value.split(' ');
            if (tags.length) {
                this.$emit('update:value', [ ...new Set([ ...this.value, ...tags ].filter(Boolean)) ]);
                this.textValue = '';
            }
        },
        removeTag(tag) {
            this.$emit('update:value', this.value.filter(t => t !== tag));
        },
        onBackspace(event) {
            if (this.type !== 'tags') return;
            if (event.key !== 'Backspace') return;
            const value = this.$refs.input.value;
            if (!value) {
                const tags = this.value.slice(0, -1);
                this.$emit('update:value', tags);
            }
        },
        incrementNumber(amount, start = 500) {
            this.updateNumber(amount);
            this.incrementTimer = this.$timeout(() => this.incrementNumber(amount, start), start);
            start = Math.max((start / 2), 10);
        },
        stopIncrementing() {
            this.$cancel(this.incrementTimer);
        },
        updateNumber(amount) {
            const number = Math.min(Math.max(Number(this.$refs.input.value) + amount, this.min || -Infinity), this.max || Infinity);
            this.$emit('update:value', number);
            this.$emit('step', number);
        },
        getURLFromHTML(value) {
            const el = document.createElement('div');
            el.innerHTML = value;
            for (const node of el.childNodes) {
                const url = this.getURLFromIframe(node);
                if (url) return url;
            }
            return;
        },
        getURLFromIframe(node) {
            if (node.tagName === 'IFRAME') {
                return node.getAttribute('src');
            }
            for (const child of node.childNodes) {
                const url = this.getURLFromIframe(child);
                if (url) return url;
            }
        },
        dirty() {
            this.isDirty = true;
        },
        edit() {
            if (this.catchEdit) return this.catchEdit();
            this.$emit('edit');
            this.isEditing = true;
        },
        save() {
            if (this.async) {
                this.$emit('save', this.onSave);
            } else {
                this.$emit('save', this.value);
                this.onSave();
            }
        },
        onSave() {
            this.isEditing = false;
            this.cachedValue = null;
        },
        cancel() {
            this.isEditing = false;
            this.$emit('update:value', this.cachedValue);
            this.$emit('cancel');
            this.cachedValue = null;
        },
    }
};
</script>

<style lang="less" scoped>

.field {
    display: flex;
    flex-direction: column;

    label {
        display: flex;
        align-items: center;
        padding: 2px 0 10px;
        margin: 0;
        font-family: var(--body), sans-serif;
        > * { margin: 0; }
        .gray { margin-left: 8px; }
    }
    input,
    textarea {
        border: 0;
        background: 0;
        appearance: none;
        border-radius: 8px;
        line-height: 24px;
        font-family: var(--body), sans-serif;
        &:read-only { cursor: default; }
    }
    &.monospaced .field-container {
        font-family: monospace;
        word-break: break-all;
    }
    &.inline-label {
        position: relative;
        label {
            position: absolute;
            pointer-events: none;
            padding: 0;
            left: 16px;
            height: 48px;
            transform-origin: left top;
            transition: var(--smooth);
            color: var(--anchor);
            font-size: 14px;
            font-weight: 400;
            z-index: 1;
            margin: 0;
        }
        .field-container {
            .input-wrapper input {
                padding: 12px 16px;
                font-size: 14px;
            }
        }
        &.value, &.focus.autocomplete, &.autofilled {
            label { transform: scale(0.6); }
            .field-container .input-wrapper input { padding: 16px 16px 8px; }
        }
    }
    .field-container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        border: 1px solid var(--steel);
        border-radius: 8px;
        background-color: #FFF;
        transition: var(--smooth);
        .input-wrapper {
            display: flex;
            align-items: center;
            &.relative { position: relative; }
            input,
            textarea {
                flex: 1 1 auto;
                width: 100%;
                padding: 11px 16px;
                font-size: 14px;
                resize: none;
                &:focus-visible {
                    outline: none;
                }
                &::placeholder {
                    font-family: var(--body), sans-serif;;
                    color: var(--anchor);
                }
            }
            textarea {
                padding: 12px 16px;
            }
            .tag {
                display: flex;
                align-items: center;
                padding: 4px 12px;
                border-radius: 16px;
                background: var(--cloud);
                margin-right: 4px;
                .cobutton {
                    padding: 0;
                    margin-left: 4px;
                }
            }
            .click-handler {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: all;
                z-index: 1;
            }
            .inline-length {
                position: absolute;
                padding: 12px;
                margin: 0;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
            }
            .actions {
                display: flex;
                align-items: center;
                padding: 4px;
                .cobutton {
                    padding: 6px;
                    margin-right: 4px;
                }
                .cobutton.success:hover > * { color: #FFF; }
            }
            .number-controls {
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 0 8px;
                .cobutton {
                    padding: 0;
                    margin: -4px 0;
                }
            }
        }
        &.rounded {
            border-radius: 48px;
            .input-wrapper {
                > input {
                    padding-left: 12px;
                    padding-right: 0;
                }
            }
        }
        &.skeleton { border-color: transparent; }
        &.focus { border-color: var(--primary); }
        &.error { border-color: var(--danger); }
        &.warning {
            background: none;
            border-color: var(--warning);
        }
    }
    .info-wrapper {
        display: flex;
        justify-content: space-between;
        min-height: 24px;

        padding: 4px 0;

        .message {
            > * { margin: 0; }
        }
        > * { margin: 0; }
        .length {
            display: flex;
            align-items: center;
            transition: var(--smooth-fast);
            border-radius: 4px;
            padding: 4px 8px;
        }
    }

    &.toolbar {
        .field-container {
            position: relative;
            .actions {
                position: absolute;
                bottom: 8px;
                right: 0;
            }
        }
    }
    &.select {
        .field-container {
            border: 0;
            .dropdown { width: 100%; }
        }
    }
    &.password {
        .input-wrapper .cobutton.light { margin-right: 8px; }
    }
    &.tags {
        .input-wrapper {
            padding: 9px 16px;
            height: 46px;
            input { padding: 0; }
        }
    }
    &.disabled, &.readonly {
        .field-container { background-color: var(--cloud); }
    }
    &.disabled {
        .field-container {
            color: var(--steel);
            input { pointer-events: all; }
        }
    }
    .toolbar {
        display: flex;
        align-items: center;
        border-top: 2px solid var(--cloud);
        padding: 8px;
        min-height: 48px;
    }
    &.small {
        label { margin: 4px 0; }
        .field-container .input-wrapper {
            input {
                padding: 8px;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: -0.2px;
            }
            .actions .cobutton { margin-right: 4px; }
        }
        .rounded {
            &.field-container .input-wrapper input {
                padding-left: 12px;
                padding-right: 0;
            }
        }
    }
    &.xs {
        .field-container {
            .input-wrapper {
                input {
                    padding: 8px 12px;
                    font-size: 12px;
                    line-height: 16px;
                }
                .inline-length {
                    position: static;
                    margin: 0 12px 0 0;
                    padding: 0;
                    transform: none;
                }
            }
        }
    }
    &.inline {
        .field-container {
            border: 0;
            input {
                padding: 4px 16px 4px 4px;
                margin-right: 48px;
                border-radius: 0;
                font-family: var(--heading);
                &::placeholder { font-family: var(--heading); }
            }
        }
    }
    &.translucent, &.dark {
        .field-container {
            background-color: rgba(255, 255, 255, 0.12);
            border: 1px solid rgba(255, 255, 255, 0.12);
            &.focus { border-color: #FFF; }
            .input-wrapper {
                input, textarea {
                    color: #FFF;
                    &::placeholder {
                        color: rgba(255, 255, 255, 0.64);
                    }
                }
            }
        }

    }
    &.translucent-dark {
        .field-container {
            background-color: ~"rgba(var(--coal-rgb), 0.25)";
            border: 1px solid ~"rgba(var(--coal-rgb), 0.25)";
            &.focus { border-color: ~"rgba(var(--coal-rgb), 0.5)"; }
            .input-wrapper {
                input, textarea {
                    color: #FFF;
                    &::placeholder {
                        color: rgba(255, 255, 255, 0.4);
                    }
                }
            }
        }

    }
    &.tel {
        .field-container {
            .input-wrapper input {
                padding-left: 0;
            }
        }
    }
    &.number {
        .field-container.stepper {
            width: auto;
            min-width: 120px;
            .input-wrapper {
                height: 100%;
                justify-content: center;
                input {
                    width: auto;
                    flex: 0 1 auto;
                    text-align: center;
                    padding: 8px 0;

                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    font-family: var(--heading), sans-serif;
                }
            }
            .number-control {
                position: absolute;
                flex-direction: row;
                padding: 0 12px;
                &.plus { right: 0; }
                &.minus { left: 0; }
            }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type=number] {
            -moz-appearance: textfield;
        }
    }
    &.anchor {
        .field-container .input-wrapper input { color: var(--anchor); }
    }

    // HACK: Override browser autofill styles
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
        background-color: transparent !important;
    }
}
</style>
<style lang="less">
.field {
    .info-wrapper {
        .txt-component.xxs .txt-component-content {
            display: flex;
            align-items: center;
            span { margin-right: 4px; }
        }
    }
    .dropdown.locale {
        position: static;
        > .cobutton {
            padding: 12px 8px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .popup .popup-inner {
            min-width: 320px;
            button .txt-component {
                flex: 1 1 auto;
                margin-left: 12px;
            }
        }
    }
    .area-code {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        height: 48px;
        font-weight: bold;
    }
}
input[type="time"]::-webkit-clear-button,
input[type="time"]::-ms-clear {
    appearance: none;
    display: none;
}
</style>
